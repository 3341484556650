<template>
  <!-- 회원 정보 수정 -->
  <coach-my-page-content-layout coach-user-modify>
    <my-page-title>
      <template #left>
        회원정보 수정
      </template>
    </my-page-title>
    <client-only>
      <template v-if="!isCoach">
        <UserLolRecordAlt ref="userLolRecord" :game-info="gameInfo" v-if="isDesktop" />
        <UserLolRecord ref="userLolRecord" :game-info="gameInfo" v-else />
      </template>
    </client-only>
    <div class="area-bottom">
      <color-button type="light-gray" class="btn-user-modify" @click="onModify">{{ $t('coaching.myPage.goEdit.button') }}</color-button>
      <p class="user-modify-description">{{ $t('coaching.myPage.goEdit.text') }}</p>
      <p class="mobile user-modify-description">{{ $t('coaching.myPage.goEdit.text') }}</p>
    </div>
  </coach-my-page-content-layout>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import { state, getter } from '@shared/utils/storeUtils';
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout.vue';
import MyPageTitle from '@/views/components/coaching/coach-my-page/MyPageTitle.vue';
import UserLolRecordAlt from '@/views/components/coaching/UserLolRecordAlt.vue';
import UserLolRecord from '@/views/components/common/record/UserLolRecord.vue';

export default {
  name: 'CoachUserModify',
  components: { UserLolRecordAlt, UserLolRecord, MyPageTitle, CoachMyPageContentLayout, ColorButton },
  metaInfo() {
    return {
      title: this.$t('meta.myInfo.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.myInfo.title') },
        { vmid: 'url', content: this.$t('meta.myInfo.url') },
        { vmid: 'description', content: this.$t('meta.description') },
        { vmid: 'keywords', content: this.$t('meta.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  data: () => ({
    gameInfo: null,
  }),
  computed: {
    userId: getter('auth', 'userId'),
    myInfo: state('auth', 'myInfo'),
    isCoach: getter('auth', 'isCoach'),
    isDesktop() {
      return this.matchedMediaDevice === 'D' || this.matchedMedia === 'TL';
    },
  },
  methods: {
    onLolRegister() {
      this.$refs?.userLolRecord?.$refs?.userRecordEmpty?.connectId();
    },
    async onModify() {
      this.$services.auth.editAccountInfo();
    },
  },
  async asyncData({ route, store }) {
    const ipList = store.state.info.games;
    const gameId = route?.params?.gameId || 'lol';
    const gameInfo = { id: gameId.toLowerCase(), ipList, stats: null };
    return { gameInfo };
  },
  async mounted() {
    this.gameInfo.stats = await this.$services.user.getUserLolSeasonInfo(this.userId);
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';

[coach-user-modify] {
  .btn-user-modify {.wh(224, 60);.m(auto);display: block;.mb(17);.mt(50);.fs(14)}
  .user-modify-description {.fs(16);.lh(24);text-align: center;.c(#a4a3ae);word-break: break-word;}
  [user-lol-record].alt {.mt(50);.p(20);box-shadow: 4px 4px 4px rgba(0, 0, 0, .4);
    .section-1 {.pr(20)}
  }
  [user-lol-record]:not(.alt) {position: relative; }
  .mobile {.hide}
  @media (@tp-down) {
    .h(100%);
    .btn-user-modify {.mt(13);font-weight: 700;}
    .user-modify-description:not(.mobile) {.hide}
    .rel;
    .area-bottom {.abs;.l(0);.r(0);.mt(300);.b(0)}
    [my-page-title] {.z(4)}
    .user-game-record.connected .point-list > ul {
      transform: unset;
    }
    .mobile.user-modify-description {position: fixed;.b(40);display: block;.w(100%);.bgc(#fff);.z(1);.p(18);.fs(15);}
    .btn-user-modify { .h(40);display: block;position: fixed;.w(100%);border-radius: 0;.b(0);.z(10);.mb(0);.mt(0);
    }
  }
  @media (@ml-up) {
    [user-lol-record]:not(.alt) {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
    }
  }
}
@media (@tp-down) {
  [app-footer] {.pb(100);
  }
}
</style>
