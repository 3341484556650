<template>
  <div user-record-account>
    <p class="thumb">
      <CdnImg :src="info.emblemUrl" dimension="80x80" />
    </p>
    <h3>{{ info.summonerName || name }} <em>{{ info.tier }} {{ info.rank }}<b>{{ info.leaguePoints }} LP</b></em>
    </h3>
    <p class="bts">
      <button class="btn refresh" @click="$emit('refresh')" />
      <button class="btn open" @click="$emit('toggle')" />
    </p>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'UserRecordAccount',
  components: { CdnImg },
  props: {
    name: String,
    info: Specific,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[user-record-account] { .bgc(#fff); .rel; .p(12, 20); z-index: 4; .-b(@c-base-gray);
  .thumb {.wh(80); .ib; .vam; .mr(16); .bgc(@c-base-gray); .br(16);
    > img {.wh(100%); .br(16);}
  }
  .bts {
    .btn {.rel; .bgc(#ebebf0); .wh(24); .br(6);
      &:before { .cnt; .abs; .lt(-0.5, -0.5); .wh(24); .o(.5);}
      &:hover {
        &:before { .o(1);}
      }
    }
    .open {
      &:before { .contain('@{icon}/Arrow_down.svg'); }
    }
    .refresh {
      &:before { .contain('@{icon}/ico_refresh.svg'); }
    }
  }
  h3 {.ib; .vam;
    em {.block; .c(#2c81ff); .fs(14); .regular;
      b {.ml(8); .chakra; .c(@c-dark);}
    }
  }
  .bts { .abs; .rt(20, 50%); .t-yc;
    button {.ml(10);}
  }
  @media (@tp-up) {
    .w(390); .bgc(transparent); .abs; .-b; .lt(10, 10); .p(0);
    .thumb {.wh(72); .br(16);}
    .bts {
      .open {.hide;}
    }
    h3 { .fs(20);}
  }
  @media (@ds-up) {
    .-r(@c-base-gray); .w(320);
  }
}
</style>
