<template>
  <ValidationObserver ref="validator">
    <ul user-record-empty>
      <li>
        <p class="thumb">
          <CdnImg dimension="40x40" :src="gameIcon" />
        </p>
        <h2>{{ emptyTitle }}</h2>
      </li>
      <li>
        <ValidationProvider :rules="gameRules" :name="currentGameId" v-slot="{errors}" mode="passive">
          <div :class="{error: errors.length}">
            <input type="text" :placeholder="gamePlaceholder" v-model="callName" class="summoner-name-input" />
            <ValidationErrors :errors="errors" />
          </div>
        </ValidationProvider>
        <PrimaryButton color="blue" @click="connectId">{{ $t('_.connectGame') }}</PrimaryButton>
      </li>
    </ul>
  </ValidationObserver>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import Specific from '@shared/types/Specific';
import _ from 'lodash';

export default {
  name: 'UserRecordEmpty',
  components: { PrimaryButton, CdnImg },
  lexicon: 'arena.userGameInfo',
  data() {
    return {
      callName: '',
    };
  },
  props: {
    info: Specific,
    name: Specific,
  },
  computed: {
    userInfo() {
      return this.$store.state.auth.myInfo;
    },
    gamePlaceholder() {
      return this.currentGame.id === 'LOL' ? this.$t('_.inputPlayer') : this.$t('_.noPlayer');
    },
    gameRules() {
      return this.currentGame.id === 'LOL' ? 'required|length:3,16' : 'required|length:4,16|alphaDash';
    },
    currentGame() {
      return _.find(this.info.ipList, o => o.id === this.info.id.toUpperCase());
    },
    emptyTitle() {
      return this.$t(`_.emptyTitle[${this.info.id}]`);
    },
    currentGameId() {
      return this.currentGame.id === 'LOL' ? 'leagueOfLegendId' : 'pubgSteamId';
    },
    gameIcon() {
      return this.currentGame?.images?.colorIcon || '';
    },
  },
  methods: {
    update() {
      this.callName = this.name;
    },
    async connectId() {
      if (!this.userInfo) {
        if (!await this.$services.auth.oAuthLogin(true)) return;
      }
      const err = await this.$getError(this.$refs.validator);
      if (err) {
        this.$scroll.scrollTo(0);
        throw err;
      }

      const r = await this.$services.user.connectGameId({ platform: this.info.id === 'pubg' ? 'pubg-steam' : 'lol', platformId: this.callName });
      this.$emit('update', r);
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[user-record-empty] { .p(20); .bgc(white);
  .summoner-name-input {.h(40);}
  li { .fs(12);
    &:nth-of-type(1) { .mb(10);
      .thumb {.wh(40); .bgc(black); .br(20); .ib; .vam;}
      h2 {.fs(20); .ib; .vam; .ml(12);}
    }
    &:nth-of-type(2) {
      > div { .h(48);
        > input {.h(100%);}
        .error {.t(48);}
        > p {.c(#2c81ff); .fs(11); .medium; .tr;}
      }
      > button {.br(17); .wf; .h(40); .fs(16); .mt(20); .ml(auto);}
    }
    .error { .rel;
      [validation-errors] { .abs; .t(40); .min-w(400); .c(#ffb600); }
    }
  }

  @media (@tp-up) {
    .wh(100%, 96); .p(28);
    .rel; .pl(215); .pr(26);
    li { .ib-list; .fs(12);
      &:nth-of-type(1) {.abs; .lt(20, 50%); .t-yc;
      }
      &:nth-of-type(2) {.rel; .pr(110);
        > div {.wf; .rel; .w(320);
          .error {.t(55);}
          > p {.tr; .block; .abs; .rb(0, -16);}
        }
        > button {.w(84); .abs; .rt(0, 50%); .t-yc; .mt(0);}
      }
      .error {
        [validation-errors] { .w(100%); .lt(-15, 50); .min-w(340);
        }
      }
    }
  }
  @media (@tl-up) {
    li {
      &:nth-of-type(2) {
        > div {
          > p { right: auto; .lt(104%, 50%); .t-yc; white-space: nowrap; }
        }
      }
    }
  }
}
</style>
