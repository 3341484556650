<template>
  <article user-lol-record class='user-game-record connected' v-if="gameData">
    <UserRecordEmpty v-if="!connected || resetData" :name="summonerName" :info="gameData" @update="connectId" ref="userRecordEmpty" />
    <template v-else>
      <UserRecordAccount :info="currentInfo" @toggle="isOpen = !isOpen" @refresh="refresh" />
      <div class="point-list">
        <ul :class="{'open': isOpen}">
          <li>
            <p class="bts type">
              <button v-for="item in types" :key="item.id" @click="activeId = item.id"
                :class="{'on':item.id === activeId}">{{ item.name }}
              </button>
            </p>
            <button class="btn init" @click="reset">{{ $t('_.reset') }}</button>
          </li>
          <li class="loading" v-if="isLoading"><p>{{ $t('_.lol.loading') }}</p></li>
          <template v-else>
            <li>
              <span>{{ $t('_.winRate') }}<b>{{ Math.floor(currentInfo.winRate * 100) }}%</b></span>
              <span>{{ $t('win') }}<b>{{ currentInfo.wins }}</b></span>
              <span>{{ $t('lose') }}<b>{{ currentInfo.losses }}</b></span>
            </li>
            <li>
              <p>
                <img :src="getPositionIcon(0)" />
                <span>
                  {{ $t(`_.lol.prefer[${getPosition(0) || 'NONE'}]`) }} <b>{{ getRatio(0) }}%</b>
                </span>
                <span>{{ $t('_.winRate') }}<b>{{ getWinRate(0) }}%</b></span>
              </p>
              <p>
                <img :src="getPositionIcon(1)" />
                <span>
                  {{ $t(`_.lol.prefer[${getPosition(1) || 'NONE'}]`) }} <b>{{ getRatio(1) }}%</b>
                </span>
                <span>{{ $t('_.winRate') }}<b>{{ getWinRate(1) }}%</b></span>
              </p>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </article>
</template>

<script>
import _ from 'lodash';
import { LOL_POSITION_ICON } from '@/constants/lol';
import UserRecordEmpty from '@/views/components/common/record/UserRecordEmpty.vue';
import UserRecordAccount from '@/views/components/common/record/UserRecordAccount.vue';

export default {
  name: 'UserLolRecord',
  components: { UserRecordAccount, UserRecordEmpty },
  lexicon: 'arena.userGameInfo',
  props: {
    gameInfo: Object,
    info: Object,
  },
  data() {
    return {
      activeId: 0,
      isOpen: false,
      callName: '',
      gameData: null,
      resetData: false,
      types: [
        { name: this.$t('_.lol.soloRank'), id: 0 },
        { name: this.$t('_.lol.freeRank'), id: 1 },
      ],
    };
  },
  computed: {
    emptyTitle() {
      return this.$t(`_.emptyTitle[${this.gameInfo.id}]`);
    },
    currentGame() {
      return _.find(this.gameData.ipList, o => o.id === this.gameData.id.toUpperCase());
    },
    connected() {
      return this.gameData && this.gameData?.stats?.statsList && this.gameData?.stats?.statsList?.length;
    },
    statsList() {
      return this.gameData?.statsList || this.gameData?.stats?.statsList || this.gameData?.stats?.rankedStatsList;
    },
    currentInfo() {
      return this.statsList && this.statsList?.length ? this.statsList[this.activeId] || this.statsList[this.activeId] : null;
    },
    prefer() {
      return this.currentInfo?.preferredPositions;
    },
    isLoading() {
      return !this.getPosition(0);
    },
    summonerName() {
      return this.currentInfo ? this.currentInfo.summonerName : '';
    },
  },
  watch: {
    gameInfo: 'update',
  },
  methods: {
    getRatio(id) {
      return Math.ceil((this.prefer[id]?.ratio ?? 0) * 100);
    },
    getWinRate(id) {
      return Math.ceil((this.prefer[id]?.winRate ?? 0) * 100);
    },
    getPosition(id) {
      return this.prefer && this.prefer.length ? this.prefer[id]?.truePosition : null;
    },
    getPositionIcon(id) {
      return LOL_POSITION_ICON[this.getPosition(id)];
    },
    reset() {
      this.resetData = true;
    },
    async refreshGameData() {
      const params = {
        providers: 'lol',
      };
      this.gameData.stats = await this.$services.user.refreshGameData(params);
    },
    async refresh() {
      await this.refreshGameData();
      this.$toast('_.lol.lolDataReset', { type: 'success' });
    },
    async connectId(data) {
      this.gameData.stats = data;
      await this.$services.auth.fetchMyInfo();
      this.$toast('_.lol.lolIdRegister', { type: 'success' });
      this.resetData = false;
    },
    update() {
      if (this.gameInfo) this.gameData = this.gameInfo;
      console.log('gameData', this.gameData);
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[user-lol-record] {
  .loading { .w(100%) !important;
    > p { .wh(100%, 130); .bgc(@c-base-gray); .br(12); .c(@c-placeholder); .fs(14); .-box; .pt(56);}
    @media (@tp-up) {
      > p { .h(48); .pt(14);}
    }
  }
}
</style>
