<template>
  <!-- CoachUserModify 페이지 안에서만 사용하는 별도 스타일. 다른 곳에서 사용하지 말 것 -->
  <article user-lol-record class='user-game-record connected alt' v-if="gameData">
    <UserRecordEmpty v-if="!connected || resetData" :name="summonerName" :info="gameData" @update="connectId" ref="userRecordEmpty" />
    <template v-else>
      <div class="section-top">
        <UserRecordAccount :info="currentInfo" @toggle="isOpen = !isOpen" @refresh="refresh" />
        <button class="btn init-icon" @click="refresh" />
        <!--      <ul :class="{'open': isOpen}">-->
        <div class="section-1">
          <p class="bts type">
            <button v-for="item in types" :key="item.id" @click="activeId = item.id"
              :class="{'on':item.id === activeId}">{{ item.name }}
            </button>
            <button class="btn init" @click="reset">{{ $t('_.reset') }}</button>
          </p>
        </div>
      </div>
      <div class="section-loading" v-if="isLoading"><p>{{ $t('_.lol.loading') }}</p></div>
      <div class="section-bottom">
        <div class="section-2" v-if="!isLoading">
          <span>{{ $t('_.winRate') }}<b>{{ Math.floor(currentInfo.winRate * 100) }}%</b></span>
          <span>{{ $t('win') }}<b>{{ currentInfo.wins }}</b></span>
          <span>{{ $t('lose') }}<b>{{ currentInfo.losses }}</b></span>
        </div>
        <div class="section-3" v-if="!isLoading">
          <p>
            <img class="position-icon" :src="getPositionIcon(0)" />
            <span>
              {{ $t(`_.lol.prefer[${getPosition(0) || 'NONE'}]`) }} <b>{{ getRatio(0) }}%</b>
            </span>
            <span>{{ $t('_.winRate') }}<b>{{ getWinRate(0) }}%</b></span>
          </p>
          <p v-if="getPosition(1)">
            <img class="position-icon" :src="getPositionIcon(1)" />
            <span>
              {{ $t(`_.lol.prefer[${getPosition(1) || 'NONE'}]`) }} <b>{{ getRatio(1) }}%</b>
            </span>
            <span>{{ $t('_.winRate') }}<b>{{ getWinRate(1) }}%</b></span>
          </p>
        </div>
      </div>
    </template>
  </article>
</template>

<script>
import UserLolRecord from '@/views/components/common/record/UserLolRecord.vue';

export default {
  extends: UserLolRecord,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[user-lol-record].alt {
  @media (@tp-up) {
    position: relative;
    .w(100%);.block;
    transform: none;
    left: unset;
    right: unset;
    top: unset;

    [user-record-account] {display: inline-block !important;position: unset;border-right: none;}
    .section-top {.block;.w(100%)}
    .section-bottom {.block;.w(100%)}
    .section-1, .section-2, .section-3 {display: inline-block;
      span {.c(#a4a3ae);.fs(14);.lh(16); .min-w(50)}
      span b {.c(#191919);.fs(20);.lh(16);.mt(8)}
    }
    .section-1 {.abs;.r(0);transform: translate(0, 50%)}
    .section-2, .section-3 {
      .flex;flex-direction: row;
    }
    .section-2 {
      > * { .flex;flex-direction: column;}
      text-align: center;
    }
    .section-3 {
      > * { .flex;flex-direction: row;
        b {.block;}
        text-align: center;
      }
    }
    [user-record-account] .bts {.hide}
    .btn.init-icon {
      .rel; .bgc(#ebebf0); .wh(24); .br(6);
      &:before { .cnt; .abs; .lt(-0.5, -0.5); .wh(24); .o(.5);}
      &:hover {
        &:before { .o(1);}
      }

      &:before { .contain('@{icon}/ico_refresh.svg'); }
    }
    .section-bottom { .flex;justify-content: center;align-items: center;}
    .position-icon {.wh(56);.ml(50);.mr(8);}
  }
}
</style>
